<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Modules</h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                v-b-modal.ModalCreateModule
            >
                <b-icon-plus-circle /> Nouveau module
            </b-button>
        </div>

        <div class="k-page-body">
            <TableModule :modules="modules" @item-clicked="onModuleSelected" />
        </div>

        <ModalCreateModule @saved="onModuleCreated" />
    </div>
</template>

<script>
import TableModule from "@/components/model/module/TableModule";
import ModalCreateModule from "@/components/model/module/ModalCreateModule";
export default {
    components: {
        TableModule,
        ModalCreateModule,
    },

    data: function() {
        return {
            modules: null,
        };
    },

    methods: {
        async fetchModules() {
            this.modules = await this.$store.dispatch("admin/getModules");
        },

        onModuleSelected(item) {
            this.$router.push({
                name: "AdminModule",
                params: {
                    moduleId: item.id,
                },
            });
        },

        async onModuleCreated() {
            this.fetchModules();
        },
    },

    async beforeMount() {
        this.fetchModules();
    },
};
</script>
