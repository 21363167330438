<template>
    <b-modal
        id="ModalCreateModule"
        size="lg"
        title="Créer un module"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormModule @saved="onSaved" class="mb-4" />
    </b-modal>
</template>
<script>
import FormModule from "@/components/model/module/FormModule";
export default {
    components: {
        FormModule,
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateModule");
            this.$emit("saved");
        },
    },
};
</script>
