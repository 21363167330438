<template>
    <div div class="k-flex-col">
        <b-table
            :items="modules"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isBusy"
            show-empty
            empty-text="Aucun module"
            @row-clicked="onRowClicked"
            thead-class="text-center"
            tbody-tr-class="pointer"
            hover
            outlined
            stacked="lg"
            fixed
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
            <template #cell(name)="data">
                {{ data.item.name }}
                <small class="text-secondary"> v.{{ data.item.version }}</small>
            </template>
            <template #cell(state)="data">
                {{ data.item.state | moduleStateFromValue }}
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
export default {
    props: {
        modules: { type: Array, required: false },
    },

    data: function() {
        return {
            perPage: 10,
            currentPage: 1,
            fields: [
                {
                    key: "name",
                    label: "Nom",
                    sortable: true,
                    tdClass: "font-weight-bold text-kalkin-1",
                    thStyle: { width: "120%" },
                },
                {
                    key: "description",
                    label: "Description",
                    thStyle: { width: "200%" },
                },
                {
                    key: "state",
                    label: "État",
                    sortable: true,
                    thStyle: { width: "70%" },
                    tdClass: "text-center",
                },
                {
                    key: "createdAt",
                    label: "Date de création",
                    sortable: true,
                    formatter: Vue.filter("dateFromISO"),
                    thStyle: { width: "100%" },
                    tdClass: "text-center",
                },
            ],
        };
    },

    computed: {
        isBusy() {
            return this.modules === null;
        },

        totalRows() {
            return this.modules ? this.modules.length : 0;
        },
    },

    methods: {
        onRowClicked(item) {
            this.$emit("item-clicked", item);
        },

        onDelete(item) {
            this.$emit("item-delete", item);
        },
    },
};
</script>

<style scoped></style>
